import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { Schedule } from 'src/app/classes/schedule';
import { Target } from 'src/app/classes/target';
import { MediaPlannerService } from 'src/app/services/media-planner.service';
import { BaseStepComponent } from '../base-step/base-step.component';
import { DialogService } from 'src/app/services/dialog.service';
import {
  TupSimpleInputDialogModelOptions,
  TupUserMessageService,
} from '@telmar-global/tup-user-message';
import { ScheduleTotalTag } from 'src/app/classes/schedule-total';
import { filter, takeUntil } from 'rxjs/operators';
import { PlanningService } from 'src/app/services/planning.service';
import { PlanningChartsComponent } from 'src/app/planning-charts/planning-charts.component';
import { ChartSettingsService } from 'src/app/services/chart-settings.service';
import { ExporterService } from 'src/app/services/exporter.service';
import { getCrossMediaProps } from 'src/app/utils/exportUtils';
import { ExportFileType, ExportType } from 'src/app/models/export.model';
import {
  RequestLoadingService,
  TargetLoading,
} from 'src/app/services/request-loading.service';
import { MatSelectChange } from '@angular/material/select';
import { MatTabNav } from '@angular/material/tabs';
import { PlanningState } from 'src/app/models/charts.model';
import { CompareMediaComponent } from 'src/app/components/compare-media/compare-media.component';
import { ViewMode } from 'src/app/components/tab-stepper/tab-stepper.component';
import {
  compareOptionKeys,
  compareOptions,
  SurveyMetrics,
} from '../../models/planning.models';
import { TupAnalyticsService } from '@telmar-global/tup-analytics';
import { GAEvents } from '../../models/analytics.model';
import { QuestionDialogModelOptions } from 'src/app/components/dialogs/confirm-dialog/confirm-dialog.component';
import { StatusSnackbarIcon } from 'src/app/components/dialogs/snackbar-generic/snackbar-generic.component';
import { Router } from '@angular/router';
import { DialogReturnData } from '../../components/dialogs/export-options-dialog/export-options.component';
import {
  TupDocument,
  TupDocumentTypeId,
} from '@telmar-global/tup-document-storage';
import {
  DocumentCampaign,
  DocumentFullSurvey,
  DocumentSurvey,
} from 'src/app/models/document.model';
import { MultiSurveyService } from '../../services/multi-survey.service';

export const MEDIATYPE_ALL: string = '[All]';

const INPUT_DIALOG_OPTIONS: TupSimpleInputDialogModelOptions = {
  useValidation: true,
  minAllowedStringLength: 1,
  useEnterToSubmit: true,
  cancelText: 'Cancel',
  autoFocus: 'Focus',
  autoFocusDelay: 10,
};

// schgedule tabs.  index kept so [All] schedules can be -1
export interface ScheduleView {
  name: string;
  index: number;
  checked: boolean;
}

export interface MediatypeView {
  name: string;
  customGroupId?: string;
  isMultiSurvey?: boolean;
}

@Component({
  selector: 'planning-step',
  templateUrl: './planning-step.component.html',
  styleUrls: ['./planning-step.component.scss'],
})
export class PlanningStepComponent
  extends BaseStepComponent
  implements OnInit, OnChanges, AfterViewInit
{
  @Input() chartsActiveIndex: number;
  @Input() visible: boolean = true;
  @Input() viewMode: ViewMode = ViewMode.Tables;
  @Input() surveyBarSelectedSurveys: DocumentFullSurvey[];

  @Output() navigation: EventEmitter<number> = new EventEmitter<number>();
  @Output() pulse: EventEmitter<number> = new EventEmitter<number>();
  @Output() surveyBarUpdate: EventEmitter<SurveyMetrics> =
    new EventEmitter<SurveyMetrics>();
  @Output() chartsActiveIndexChange: EventEmitter<number> =
    new EventEmitter<number>();
  @Output() viewModeChange: EventEmitter<ViewMode> =
    new EventEmitter<ViewMode>();
  @Output() compareOptionChange: EventEmitter<string> =
    new EventEmitter<string>();
  @Output() activeScheduleTabChange: EventEmitter<number> =
    new EventEmitter<number>();

  @ViewChild('planningCharts') planningCharts: PlanningChartsComponent;
  @ViewChild('scheduleTabGroup') scheduleTabGroup: MatTabNav;

  @ViewChild('compareMediaTable') compareMediaTable: CompareMediaComponent;

  surveyBarSurvey: SurveyMetrics;
  viewModeIndex: number = 0;
  label: string = 'planning';
  isLoading: boolean = false;
  compareOptions = compareOptions;
  currentCompareOption: string = this.compareOptions[0].data;
  currentCompareOptionLabel: string = this.compareOptions[0].label;
  targetTooltips: string[] = [];
  scheduleTooltips: string[] = [];
  surveyBarCurrentSurvey: DocumentSurvey;
  hasPrimarySurveyMetrics: boolean;

  get internalSurvey(): SurveyMetrics {
    const { primarySurvey } = this.mediaplannerService.plan;
    const tgt = this.targetInView;
    return {
      surveyCode: primarySurvey.code,
      authorizationGroup: primarySurvey.authorizationGroup,
      surveyTitle: primarySurvey.title,
      copyrightInfo: primarySurvey.copyrightInfo || '',
      surveyInfo: primarySurvey.surveyInfo || '',
      provider: primarySurvey.provider,
      targetTitle: tgt?.title,
      population: tgt.documentTarget.customPopulation
        ? tgt.documentTarget.customPopulation
        : tgt?.population,
      sample: tgt?.sample,
      units: this.mediaplannerService.plan.surveyMetaData.meta(
        primarySurvey.code
      ).reportUnits,
      hasCustomPop: !!tgt.documentTarget.customPopulation,
    };
  }

  get targets(): Target[] {
    return this.mediaplannerService.plan.targets.filter(
      (target) => target.planningTarget
    );
  }

  get allTargets(): Target[] {
    return this.mediaplannerService.plan.targets;
  }

  get schedules(): Schedule[] {
    return this.mediaplannerService.plan.schedules;
  }

  get targetInView(): Target {
    return this.allTargets[this.currentTarget];
  }

  get scheduleInView(): Schedule {
    return this.schedules[this.currentSchedule];
  }

  unitsText: string;
  currentSchedule: number;
  activeScheduleTab: number;
  currentTarget: number;
  selectedMediaTabs: number[] = [0];

  selectedTargets: Target[] = [];
  selectedSchedules: Schedule[] = [];
  comparePlansTotal: Schedule;

  screenSchedules: ScheduleView[] = [];

  processingMultiSurvey: boolean = false;

  private unsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private mediaplannerService: MediaPlannerService,
    private userMessage: TupUserMessageService,
    private planningService: PlanningService,
    private chartSettingsService: ChartSettingsService,
    private exporterService: ExporterService,
    private dialogService: DialogService,
    private requestLoadingService: RequestLoadingService,
    private analyticsService: TupAnalyticsService,
    private router: Router,
    private multiSurveyService: MultiSurveyService
  ) {
    super();
  }

  ngAfterViewInit(): void {
    // if (this.refreshCompareOption) {
    //   this.refreshCompareOption = false;
    //   console.log("EMITTING", this.compareMedia)
    //   this.compareMedia.reload.emit();
    // }
  }

  ngOnInit(): void {
    this.populateSchedules();
    this.requestLoadingService.loading$
      .pipe(
        takeUntil(this.unsubscribe),
        filter(
          (targetLoading: TargetLoading) => targetLoading.target === 'global'
        )
      )
      .subscribe((targetLoading) => {
        this.isLoading = targetLoading.isLoading;
      });

    this.mediaplannerService.surveyChanged.subscribe(() => {
      //this.cleanupAfterSurveyChanged();
    });

    // disable clicking other tab steps while multi survey data is processed
    this.multiSurveyService.multiSurveyStartProcessing.subscribe(
      (currentTab) => {
        if (currentTab === 'planning') {
          this.processingMultiSurvey = true;
          this.checkReady();
        }
      }
    );
    this.multiSurveyService.multiSurveyTargetSelected.subscribe((eventData) => {
      if (eventData.currentTab === 'planning') {
        this.processingMultiSurvey = false;
        this.checkReady();
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const viewMode = changes['viewMode'];
    const chartsActiveIndex = changes['chartsActiveIndex'];

    if (
      viewMode &&
      viewMode.previousValue !== viewMode.currentValue &&
      !viewMode.firstChange
    ) {
      this.initialiseViewModeChange();
    }
    if (
      chartsActiveIndex &&
      chartsActiveIndex.previousValue !== chartsActiveIndex.currentValue &&
      !chartsActiveIndex.firstChange
    ) {
      this.loadChartsData();
    }
  }

  // editor radio button clicked to toggle tables vs. charts
  initialiseViewModeChange() {
    this.viewModeIndex = [ViewMode.Tables, ViewMode.Charts].indexOf(
      this.viewMode
    );

    if (this.viewMode === ViewMode.Charts) {
      if (!this.mediaplannerService.plan.anyResults()) {
        const options: QuestionDialogModelOptions = {
          buttons: [
            { caption: 'Close', data: 'cancel' },
            { caption: 'Back to plan', data: 'backToPlan', flat: true },
          ],
          closeButton: { caption: 'Close', data: 'cancel' },
          snackbar: {
            type: 'warning',
            message:
              'Please add some values to your plan in order to see the visualisation.',
            align: 'center',
            icon: StatusSnackbarIcon.Warning,
          },
        };

        this.dialogService
          .confirmation('', 'Nothing to see here yet', options)
          .afterClosed()
          .subscribe((button) => {
            if (button.data === 'backToPlan') {
              const currentUrl = window.location.href;
              if (currentUrl.includes('/new/chart')) {
                this.router.navigate(['/new/data'], {
                  queryParams: {
                    tab: 2,
                  },
                });
              } else {
                this.router.navigate(
                  [`edit/${this.mediaplannerService.plan.documentId}/data`],
                  {
                    queryParams: {
                      tab: 2,
                    },
                  }
                );
              }
            }
          });
      }

      this.loadChartsData();
    }

    if (this.viewMode === ViewMode.Tables) {
      this.chartSettingsService.planningState.scheduleCountChanged = false;
      this.chartSettingsService.planningState.targetCountchanged = false;
      this.chartSettingsService.planningState.vehicleCountChanged = false;
    }

    this.viewModeChange.emit(this.viewMode);
  }

  loadChartsData() {
    this.hasPrimarySurveyMetrics = !!this.surveyBarSurvey.surveyInfo;
    const state: PlanningState = {
      currentMediaType: MEDIATYPE_ALL,
      currentSchedule: this.currentSchedule,
      currentTarget: this.currentTarget,
      targetCountchanged:
        this.chartSettingsService.planningState.targetCountchanged,
      vehicleCountChanged:
        this.chartSettingsService.planningState.vehicleCountChanged,
      scheduleCountChanged:
        this.chartSettingsService.planningState.scheduleCountChanged,
    };
    this.planningCharts.loadData(state);
  }

  checkReady() {
    const ready = !this.processingMultiSurvey;
    this.mediaplannerService.campaignStatus.readyForAudiences.next(ready);
    this.mediaplannerService.campaignStatus.readyForMedia.next(ready);
    this.mediaplannerService.campaignStatus.readyForPlanning.next(true);
    this.mediaplannerService.campaignStatus.readyToShowSpot.next(
      this.checkBroadcast()
    );
    this.mediaplannerService.campaignStatus.readyForSpot.next(
      this.checkAnySpotplans()
    );

    if (this.checkAnySpotplans) {
      this.mediaplannerService.plan.tabReady = 'spots';
    } else {
      this.mediaplannerService.plan.tabReady = 'planning';
    }
  }

  checkAnySpotplans(): boolean {
    return this.scheduleInView.spotplans.any();
  }

  // tabset containing schedules, changed
  onScheduleChange(tabIndex: number) {
    if (this.currentCompareOption !== compareOptionKeys.plans) {
      this.currentSchedule = tabIndex;
      this.buildTargetsTooltips();
      this.checkReady();
      setTimeout(() => {
        this.activeScheduleTab = tabIndex;
        this.activeScheduleTabChange.emit(tabIndex);
      }, 100);
    }
  }

  onMultiSurveyPopulationChange() {
    this.surveyBarSurvey = this.internalSurvey;
  }

  // selected target chip changed
  onTargetChange(target: number) {
    this.currentTarget = target;

    this.surveyBarSurvey = this.internalSurvey;
    this.surveyBarUpdate.emit(this.internalSurvey);
    this.buildSchedulesTooltips();
    this.buildTargetsTooltips();
  }

  onCompareOptionChange(event: MatSelectChange) {
    if (event.value === compareOptionKeys.plans) {
      this.selectedMediaTabs = [this.currentSchedule];
      this.selectedSchedules = [this.schedules[this.currentSchedule]];
      this.screenSchedules.forEach((schedule, index) => {
        schedule.checked = this.currentSchedule === index;
      });
    }

    this.compareOptionChange.emit(event.value);
    this.currentCompareOption = event.value;
    this.currentCompareOptionLabel = this.compareOptions.find(
      (option) => option.data === event.value
    ).label;
    this.reloadCompareView();
    this.analyticsService.sendPageview(`compare by ${event.value}`);
  }

  // send a reload emit to the current compareview
  reloadCompareView() {
    setTimeout(() => {
      this.currentCompareOption === 'media' && this.compareMediaTable
        ? this.compareMediaTable.reload.emit()
        : null;
    });
  }

  private buildSchedulesExportOptions() {
    if (this.currentCompareOption === compareOptionKeys.plans) {
      return this.selectedSchedules.map((schedule, index) => {
        return {
          id: index,
          label: schedule.name,
          checked: true,
        };
      });
    }
    return this.schedules.map((schedule, index) => {
      return {
        id: index,
        label: schedule.name,
        checked: schedule === this.schedules[this.currentSchedule],
      };
    });
  }

  checkBroadcast() {
    let result = false;
    this.allTargets.forEach((target) => {
      const containsBroadcast = !!target.vehicles.find(
        (veh) => veh.dayparts && veh.dayparts.length
      );
      if (containsBroadcast) {
        result = true;
      }
    });
    return result;
  }

  private buildTargetsExportOptions() {
    return this.targets.map((target) => {
      return {
        id: target.id,
        label: target.title,
        checked:
          this.currentCompareOption === compareOptionKeys.audiences
            ? this.selectedTargets.includes(target)
            : true,
      };
    });
  }

  getSelectedExportSchedules(dialogDataSchedules: (number | string)[]) {
    let exportSchedules = this.schedules.filter((sch) =>
      dialogDataSchedules.includes(sch.name)
    );
    if (
      this.currentCompareOption === compareOptionKeys.plans &&
      this.comparePlansTotal
    ) {
      exportSchedules = [...exportSchedules, this.comparePlansTotal];
    }
    return exportSchedules;
  }
  getSelectedExportTargets(dialogDataTargets: (number | string)[]) {
    return this.mediaplannerService.plan.targets.filter((target) =>
      dialogDataTargets.includes(target.id)
    );
  }

  // called from the editor, handle exporting for tables and charts
  export(fileType: ExportFileType, type: ExportType) {
    const chartsProps = this.planningCharts.activeCharts.map((chart) =>
      getCrossMediaProps(chart)
    );
    switch (type) {
      case ExportType.charts:
        switch (fileType) {
          case ExportFileType.pptx:
            this.exporterService.exportToPPTX(chartsProps);
            break;
          case ExportFileType.xlsx:
            this.exporterService.exportChartsToXlsx(chartsProps);
            break;
          case ExportFileType.googleSlides:
            this.exporterService.exportToSlides(chartsProps);
            break;
          case ExportFileType.googleSheets:
            this.exporterService.exportChartsToSheets(chartsProps);
            break;
        }
        break;
      case ExportType.data:
        switch (fileType) {
          case ExportFileType.json:
            this.mediaplannerService
              .getDocumentCampaign(TupDocumentTypeId.CMP_CAMPAIGN)
              .subscribe((campaignDocument: TupDocument<DocumentCampaign>) => {
                if (campaignDocument) {
                  this.exporterService.exportToJSON(campaignDocument);
                }
              });
            break;
          case ExportFileType.xlsx:
            this.dialogService
              .openExportOptions({
                schedules: this.buildSchedulesExportOptions(),
                targets: this.buildTargetsExportOptions(),
                compareOption: this.currentCompareOption,
              })
              .afterClosed()
              .subscribe((dialogData: DialogReturnData) => {
                if (dialogData) {
                  const exportSchedules = this.getSelectedExportSchedules(
                    dialogData.schedulesNames
                  );
                  const exportTargets = this.getSelectedExportTargets(
                    dialogData.targetsIds
                  );
                  this.exporterService.mediaType_All = MEDIATYPE_ALL;
                  this.exporterService.exportToXLSX(
                    exportSchedules,
                    exportTargets,
                    this.currentCompareOption
                  );
                }
              });
            break;
          case ExportFileType.mrf:
            this.exportMrf();
            break;
        }
        break;
    }
  }

  exportMrf() {
    this.compareMediaTable ? this.compareMediaTable.exportMrf() : null;
  }

  // create a new schedule and copy in an existing plan if required
  onAddSchedule(duplicate: boolean) {
    const sched = this.schedules[this.currentSchedule];
    const name = duplicate
      ? `Copy of - ${sched.name}`
      : `Plan ${this.schedules.length + 1}`;

    const newSchedule = this.mediaplannerService.plan.addSchedule(
      name,
      duplicate ? sched : null
    );

    if (!duplicate) {
      this.mediaplannerService.plan.costings.applyDefaultCosts(
        this.mediaplannerService.plan.targets,
        this.mediaplannerService.plan.baseTarget,
        [newSchedule],
        true
      );
    }

    this.chartSettingsService.planningState.scheduleCountChanged = true;

    this.populateSchedules();
    this.buildSchedulesTooltips();
    this.onScheduleChange(this.screenSchedules.length - 1);
    this.analyticsService.e(GAEvents.plan_inline_menu, {
      action: `plan_tabs|${duplicate ? 'duplicate' : 'add'} plan`,
    });
  }

  // schedule edit dialog with rename
  onEditSchedule() {
    const sch = this.schedules[this.currentSchedule];

    this.userMessage
      .openSimpleInputDialog(sch.name, 'Rename', sch.name, INPUT_DIALOG_OPTIONS)
      .afterClosed()
      .subscribe((ans) => {
        if (ans) {
          sch.name = ans;
          this.screenSchedules.find(
            (sch) => sch.index === this.currentSchedule
          ).name = ans;
          this.chartSettingsService.planningState.scheduleCountChanged = true;
        }
      });
    this.analyticsService.e(GAEvents.plan_inline_menu, {
      action: `plan_tabs|rename plan`,
    });
  }

  // remove schedule, called from schedules burger menu
  onDeleteSchedule() {
    if (this.schedules.length == 1) {
      this.userMessage.openMessageDialog(
        'You cannot delete all your plans',
        'Delete'
      );
      return;
    }

    const sch = this.schedules[this.currentSchedule];
    this.dialogService
      .question(`Do you want to delete your plan '${sch.name}'?`, 'Delete')
      .subscribe((ans) => {
        if (ans) {
          this.mediaplannerService.plan.deleteSchedule(this.currentSchedule);
          this.chartSettingsService.planningState.scheduleCountChanged = true;

          this.populateSchedules();
          const scheduleIndex =
            this.currentSchedule - 1 === this.screenSchedules.length - 1
              ? this.schedules.length - 1
              : this.currentSchedule;
          this.onScheduleChange(scheduleIndex);
          this.buildSchedulesTooltips();
        }
      });
    this.analyticsService.e(GAEvents.plan_inline_menu, {
      action: `plan_tabs|delete plan`,
    });
  }

  // user navigated to another step
  saveData(): Observable<boolean> {
    super.saveData();
    return of(true);
  }

  // user navigated here from another step
  loadData() {
    super.loadData();

    this.unitsText = this.mediaplannerService.plan.surveyMetaData.meta(
      this.mediaplannerService.plan.primarySurvey.code
    ).reportUnitText;

    // reset our plan chart planstate
    this.chartSettingsService.planningState.scheduleCountChanged = false;
    this.chartSettingsService.planningState.targetCountchanged = false;
    this.chartSettingsService.planningState.vehicleCountChanged = false;

    // on screen schedule list
    this.populateSchedules();
    this.onScheduleChange(0);
    this.activeScheduleTab = 0;

    this.currentTarget = this.allTargets.indexOf(this.targets[0]);
    this.buildSchedulesTooltips();
    this.surveyBarSurvey = this.internalSurvey;
    this.surveyBarUpdate.emit(this.surveyBarSurvey);

    this.selectedTargets = [this.allTargets[this.currentTarget]];
    this.selectedSchedules = [this.schedules[this.currentSchedule]];

    this.reloadCompareView();
    this.checkReady();
    // emit a reload to the compare media event to refresh against any data changes
    this.surveyBarCurrentSurvey = this.mediaplannerService.plan.currentSurvey;
  }

  populateSchedules() {
    this.screenSchedules = this.schedules.map((schedule, index) => {
      return {
        name: schedule.name,
        index,
        checked: this.isPlanningStepChecked(index),
      };
    });

    setTimeout(() => {
      this.scheduleTabGroup.updatePagination();
    }, 200);
  }

  // called from compare media component to report back any changes to the campaign results
  onCompareMediaPlanUpdated(value: boolean) {
    this.buildSchedulesTooltips();
    this.buildTargetsTooltips();
    this.checkReady();
  }

  // called from compare plans component when a plan is added to the compare table in order to make totals result available for export
  onScheduleTotalUpdated(totalsSchedule) {
    this.comparePlansTotal = totalsSchedule;
  }

  onMultipleTargetSelect(selectedIdexes: number[]) {
    this.selectedTargets = [];
    const indexes = selectedIdexes;
    indexes.sort((a, b) => {
      return a - b;
    });
    indexes.forEach((targetIndex) => {
      this.selectedTargets.push(this.allTargets[targetIndex]);
    });
  }

  isPlanningStepChecked(selectedIndex: number) {
    return this.selectedMediaTabs.includes(selectedIndex);
  }

  onPlanCheckboxChange(event: any, selectedIndex: number) {
    event.preventDefault();
    if (this.selectedMediaTabs.includes(selectedIndex)) {
      if (this.selectedMediaTabs.length > 1) {
        const existingIndex = this.selectedMediaTabs.indexOf(selectedIndex);
        this.selectedMediaTabs.splice(existingIndex, 1);
      }
    } else {
      this.selectedMediaTabs.push(selectedIndex);
    }
    this.screenSchedules[selectedIndex].checked =
      this.isPlanningStepChecked(selectedIndex);
    this.selectedSchedules = [];
    const indexes = this.selectedMediaTabs;
    indexes.sort((a, b) => {
      return a - b;
    });
    indexes.forEach((targetIndex) => {
      this.selectedSchedules.push(this.schedules[targetIndex]);
    });
  }

  // tooltips for the schedule bar
  buildSchedulesTooltips() {
    this.scheduleTooltips = [];
    this.schedules.forEach((schedule) => {
      const data = this.mediaplannerService.plan.getTotalsPlanningData(
        'total',
        ScheduleTotalTag.total,
        this.targetInView,
        schedule
      );

      this.scheduleTooltips.push(
        `Reach: ${(<number>data.reachPct).toFixed(2)}% | Avg. Freq.: ${(<
          number
        >data.avgFrequency).toFixed(1)}`
      );
    });
  }

  // tooltips for the target bar
  buildTargetsTooltips() {
    this.targetTooltips = [];

    this.targets.forEach((target) => {
      const data = this.mediaplannerService.plan.getTotalsPlanningData(
        'total',
        ScheduleTotalTag.total,
        target,
        this.scheduleInView
      );

      this.targetTooltips.push(
        `${target.title} \n\n  Reach: ${(<number>data.reachPct).toFixed(
          2
        )}% | Avg. Freq.: ${(<number>data.avgFrequency).toFixed(1)}`
      );
    });
  }
}
