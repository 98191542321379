export const MISSING_DEFAULT_COSTS =
  'The cost associated to this media vehicle is either not applicable or deemed as 0.00';

export enum DefaultVehicleCostType {
  notFound = 'NOT FOUND',
  unitCost = 'UNIT COST',
  cpm = 'CPM',
}
export interface DefaultCost {
  unitCost?: number;
  baseCpm?: number;
  applied: boolean;
}

export interface GetVehicleCostsResponse {
  success: boolean;
  message: string;
  vehicleCount: number;
}

export interface DefaultVehicleCostsRequest {
  surveyProvider: string;
  vehicles: string[];
}

export interface DefaultVehicleCostsResponse {
  message: string;
  success: boolean;
  vehicleCosts: { costType: string; value: number }[];
}
