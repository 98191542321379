import { EventEmitter, Injectable } from '@angular/core';
import { TupAuthService, UserContainer } from '@telmar-global/tup-auth';
import {
  TupDocument,
  TupDocumentTypeId,
  TupDocumentTypes,
  TupTag,
} from '@telmar-global/tup-document-storage';
import {
  BehaviorSubject,
  combineLatest,
  forkJoin,
  Observable,
  of,
  Subscriber,
} from 'rxjs';
import {
  MediaPlanner,
  defaultFreqDistributionSettings,
} from '../classes/media-planner';
import { Target, TargetStatus } from '../classes/target';
import {
  DocumentCampaign,
  DocumentSurvey,
  DocumentFullSurvey,
  DocumentTarget,
  UNSAVED_DOCUMENT,
  DocumentOrigin,
  DocumentBrief,
  DocumentTargetResults,
  DocumentSchedule,
} from '../models/document.model';
import { CodebookService } from './codebook.service';
import { DocumentService } from './document.service';
import { EngineService } from './engine.service';
import { cloneDeep, uniqBy } from 'lodash';
import { PlanningColumnsService } from './planning-columns.service';
import { AutoSaveService } from './auto-save.service';
import { isNotNullOrUndefined } from '../pipes/pipeable-operators';
import { ReachFreqParametersService } from './reach-freq-parameters.service';
import { SurveyMetaDataResponse } from '../models/codebook.models';
import { ScheduleTotalTag } from '../classes/schedule-total';
import { TargetVehicle } from '../classes/vehicle';
import { TargetAudienceSetVehicle } from '../models/engine.media-evaluation.models';
import { TupUserPreferenceStorageService } from '@telmar-global/tup-user-preference-storage';
import { environment } from 'src/environments/environment';
import { first } from 'rxjs/operators';
import { TupLoggerService } from '@telmar-global/tup-logger-angular';
import { ChartSettingsService } from './chart-settings.service';
import { MatDialogConfig } from '@angular/material/dialog';
import { MultiTargetEvaluationResponse } from '../models/engine.target-evaluation.models';
import {
  CampaignPreparationResponse,
  SurveyMetrics,
} from '../models/planning.models';
import {
  SaveDocumentDialogData,
  SaveDocumentDialogResult,
  getSaveDocumentDialogData,
} from '../components/dialogs/save-document-dialog/save-document-dialog.component';
import { Survey } from '@telmar-global/tup-audience-groups';
import { DocumentDialogService } from './document-dialog.service';
import { ResultType } from '../classes/result';
import { DaypartMetrics } from '../classes/daypart-result';
import { SaveAsDialogOptionsModel } from '../pages/editor/editor.component';
import { MultiSurvey } from '../classes/multi-survey';
import { Compression } from '../models/compression';
import { DialogService } from './dialog.service';

export interface LoadDocumentResult {
  success: boolean;
  warnings?: string[];
  errors?: string[];
  campaignFile: TupDocument<DocumentCampaign>;
}

export interface LoadExploreReportResult {
  success: boolean;
  warnings?: string[];
  errors?: string[];
  exploreFile: TupDocument<unknown>;
}
export interface CampaignStatus {
  readyForAudiences: BehaviorSubject<boolean>;
  readyForMedia: BehaviorSubject<boolean>;
  readyForPlanning: BehaviorSubject<boolean>;
  readyForSpot: BehaviorSubject<boolean>;
  readyToShowSpot: BehaviorSubject<boolean>;
}

export enum surveyType {
  primary,
  current,
}

@Injectable({
  providedIn: 'root',
})
export class MediaPlannerService {
  recentSurveys: Survey[];
  selectedSurveysUsed: Survey[] = [];
  surveysList: DocumentSurvey[];
  surveyPreferenceOption: number;

  plan: MediaPlanner; // current media plan
  surveyChanged: EventEmitter<any> = new EventEmitter();
  readonly NEW_CAMPAIGN_TITLE: string = 'New campaign';

  campaignStatus = {
    readyForAudiences: new BehaviorSubject<boolean>(true),
    readyForMedia: new BehaviorSubject<boolean>(false),
    readyForPlanning: new BehaviorSubject<boolean>(false),
    readyForSpot: new BehaviorSubject<boolean>(false),
    readyToShowSpot: new BehaviorSubject<boolean>(false),
  };

  constructor(
    private codebookService: CodebookService,
    private engineService: EngineService,
    private documentDialogService: DocumentDialogService,
    private documentService: DocumentService,
    private columnsService: PlanningColumnsService,
    private autoSaveService: AutoSaveService,
    private loggerService: TupLoggerService,
    private userPreferenceService: TupUserPreferenceStorageService,
    private RFParamService: ReachFreqParametersService,
    private authService: TupAuthService,
    private chartSettingsService: ChartSettingsService
  ) {
    this.plan = new MediaPlanner();
    this.autoSaveService.autoSaveTimer.subscribe(() =>
      this.updateAutoSaveCampaign().subscribe()
    );

    this.dirty(false);
    this.getRecentSurveysList();
    this.getSelectedSurveysUsed();
    this.codebookService.getSurveys().subscribe((surveys: DocumentSurvey[]) => {
      this.surveysList = surveys;
    });

    // get the global RF calculation parameters and save in a class.  Used for processing R&F and UI displays
    this.RFParamService.getCalculationParameters().subscribe((success) => {
      this.plan.surveyMetaData.copyReachFreqData(
        success ? this.RFParamService.parameters : [],
        success ? this.RFParamService.percentageInserts : []
      );
    });
  }

  ngOnDestroy() {
    this.autoSaveService.autoSaveTimer.unsubscribe();
  }

  // passive autosave.  Waits 2 seconds for any other user inputs before saving
  dirty(value: boolean = true) {
    this.plan.campaignDirty = value;
    this.autoSaveService.dirty(value);
  }

  // start an immediate auto save rather than wait 2s
  saveProgress() {
    this.autoSaveService.saveNow();
  }

  clearOnSurveyChange(singleSurvey?: boolean) {
    this.plan.clearOnSurveyChange(singleSurvey);
  }

  clearAll() {
    this.plan.clearAll();
    this.plan.brief = this.clearBriefing();
    this.chartSettingsService.clearAll();
    this.codebookService.clearCache();
  }

  /**
   * Adds a new target to the current plan and tabs its vehicle audiences
   * Audience list to tab is pulled from one of the other current targets in the array
   *
   * @param target DocumentTarget object
   * @returns An Observable of type Target where the target will be null if it already exists in the targets array
   */
  addTarget(
    target: DocumentTarget,
    vehicles: TargetVehicle[] = null,
    allowDuplicate: boolean = false
  ): Observable<Target> {
    const newTarget = this.plan.addTarget(target, vehicles, allowDuplicate); // add to the planning object.  Will populate the vehicle array if one is available

    return [TargetStatus.active, TargetStatus.updated].includes(
      newTarget.status
    )
      ? this.updateTargetVehicleAudiences(newTarget)
      : of(newTarget);
  }

  /**
   * If the target coding expressions haved changed, refresh vehicle audiences and potential reaches
   *
   * @param target DocumentTarget object
   * @returns An Observable of type Target where the target will be null if it already exists in the targets array
   */
  updateTarget(target: Target): Observable<Target> {
    return new Observable((ob) => {
      this.updateTargetVehicleAudiences(target).subscribe((newTarget) => {
        const targetIndex = this.plan.targets.findIndex(
          (tgt) => tgt.id === target.id
        );
        if (targetIndex !== -1)
          this.plan.targets[targetIndex] = cloneDeep(newTarget);
        ob.next(newTarget);
        ob.complete();
      });
    });
  }

  // calcuate all vehicle audiences and potential reaches for the given target
  private updateTargetVehicleAudiences(
    target: Target,
    includeBaseTarget: boolean = true
  ): Observable<Target> {
    // build list of vehicles for evaluation. check if vehicle is a daypart and build as needed
    const fetchVehicles = (
      vehs: TargetVehicle[]
    ): TargetAudienceSetVehicle[] => {
      const vehicles: TargetAudienceSetVehicle[] = [];
      const validVehs = vehs.filter((veh) => veh.id && !veh.isMultiSurvey);

      validVehs.forEach((veh) => {
        vehicles.push({
          id: veh.id as string,
          mnemonic: veh.mnemonic,
          surveyCode: veh.survey?.code || this.plan.currentSurvey.code,
        });
        vehicles[vehicles.length - 1].daypartIds = veh.dayparts
          ? veh.dayparts.map((dp) => dp.id)
          : [];
      });

      return vehicles;
    };

    return new Observable((ob) => {
      // target was added and has some vehicles that need tabbing
      if (target.vehicles.length) {
        // tab its vehicle audiences
        const vehIds = fetchVehicles(target.vehicles);

        const targets = [target];
        if (includeBaseTarget) targets.push(this.plan.baseTarget);

        this.engineService
          .getAudiencesForTargetSet(
            target.documentTarget.survey.code,
            targets,
            vehIds,
            this.plan.selectedSurveys.length > 1
              ? this.plan.selectedSurveys.map((survey) => ({
                  surveyCode: survey.code,
                  authorizationGroup: survey.authorizationGroup,
                  ESGProvider: survey.esgProviders[0],
                }))
              : undefined
          )
          .subscribe((res) => {
            if (res.success && res.results?.length) {
              const resultTarget = res.results[0];
              const baseTarget = includeBaseTarget ? res.results[1] : null;

              // loop through vehicle results and populate whether daypart or regular
              resultTarget.vehicles.forEach((resultVehicle, index) => {
                // populate all audiences, either vehicles or dayparts (not daypart parents)
                const targetVehicle = target.vehicle(resultVehicle.Id);
                targetVehicle.audience = resultVehicle.audience; // with mg, use the grossAudience as you would the regular audience.
                targetVehicle.grossAudience = resultVehicle.grossaudience || 0; // Display the 'regular' audience on screen for mg vehicles
                targetVehicle.resps = resultVehicle.sample;
                targetVehicle.potentialReach = resultVehicle.potentialReach;

                if (includeBaseTarget) {
                  targetVehicle.compositionIndex =
                    resultTarget.universe * baseTarget.universe
                      ? (resultVehicle.audience /
                          resultTarget.universe /
                          (baseTarget.vehicles[index].audience /
                            baseTarget.universe)) *
                        100
                      : 0;
                  targetVehicle.compositionPct = baseTarget.universe
                    ? (resultVehicle.audience /
                        baseTarget.vehicles[index].audience) *
                      100
                    : 0;
                }

                // extract the dayparts results
                if (resultVehicle.dayparts && targetVehicle.dayparts) {
                  targetVehicle.dayparts.forEach((targetDaypart) => {
                    const resultDaypart = resultVehicle.dayparts.find(
                      (dp) => dp.Id === targetDaypart.mnemonic
                    );
                    if (resultDaypart) {
                      targetDaypart.audience = resultDaypart.audience; // with mg, use the grossAudience as you would the regular audience.
                      targetDaypart.grossAudience =
                        resultDaypart.grossaudience || 0; // Display the 'regular' audience on screen for mg vehicles
                      targetDaypart.resps = resultDaypart.sample;
                      targetDaypart.potentialReach =
                        resultDaypart.potentialReach;
                    }
                  });
                }
              });
            }
            ob.next(target);
            ob.complete();
          });
      } else {
        // no vehicles or target added already exists
        ob.next(target);
        ob.complete();
      }
    });
  }

  // delete a target from all the current totals using the coding as the reference
  deleteTarget(coding: string, passive: boolean): void {
    const index = this.plan.targets.findIndex(
      (target) => target.documentTarget.coding === coding
    );
    if (index !== -1) this.plan.deleteTarget(index, passive);
  }

  // prepare defaut options
  // Fetch list of cleared surveys (or use the currently fetched)
  // select the first in the list
  // clearAll() call
  prepare(): Observable<CampaignPreparationResponse> {
    return new Observable((observable) => {
      this.clearAll();
      this.plan.addSchedule('Plan 1');
      this.plan.title = this.NEW_CAMPAIGN_TITLE;

      this.prepareSurveys().subscribe(
        (prepResponse: CampaignPreparationResponse) => {
          observable.next(prepResponse);
          observable.complete();
        }
      );
      // retrieved surveys stored in codebookservice.  This is only ran if not already done so
    });
  }

  isSurveyMrfCompatible(actionType: string): boolean {
    const current = this.plan.currentSurvey;
    if (actionType === 'import') {
      return current ? current.isMrfGlobaldemomap : false;
    }

    if (actionType === 'export') {
      return current ? current.isMrfProfile : false;
    }

    return false;
  }

  getSelectedSurveysMetrics(): SurveyMetrics[] {
    const selectedSurveys = cloneDeep(this.plan.selectedSurveys);

    return selectedSurveys.map((survey) => {
      const surveyMetaData = this.plan.surveyMetaData.meta(survey.code);
      return {
        surveyCode: survey?.code,
        authorizationGroup: survey?.authorizationGroup,
        surveyTitle: survey?.title,
        surveyInfo: survey?.surveyInfo || '',
        copyrightInfo: survey?.copyrightInfo || '',
        provider: survey?.provider,
        targetTitle: 'All Adults',
        population: survey?.population,
        sample: survey?.sample,
        units: surveyMetaData ? surveyMetaData.reportUnits : 1,
      };
    });
  }

  getSurveyMetrics(type: surveyType = surveyType.primary): SurveyMetrics {
    const current =
      type === surveyType.primary
        ? this.plan.primarySurvey
        : this.plan.currentSurvey;

    return {
      surveyCode: current.code,
      authorizationGroup: current.authorizationGroup,
      surveyTitle: current.title,
      surveyInfo: current.surveyInfo || '',
      copyrightInfo: current.copyrightInfo || '',
      provider: current.provider,
      targetTitle: 'All Adults',
      population: current.population,
      sample: current.sample,
      units: this.plan.surveyMetaData.meta(current.code).reportUnits || 1,
    };
  }

  getSelectedSurveys(): DocumentFullSurvey[] {
    return this.plan.selectedSurveys;
  }

  getSurveyMetaData(survey: DocumentSurvey): Observable<null> {
    return new Observable((ob) => {
      const exists = !!this.plan.surveyMetaData.meta(survey.code);
      if (exists) {
        ob.next();
        ob.complete();
      } else {
        const reqs = {
          metaData: this.codebookService.getSurveyMetadata(survey.code),
          medTypes: this.codebookService.getMediatypesId(survey.code),
        };

        forkJoin(reqs).subscribe((data) => {
          this.plan.surveyMetaData.copyMetaData(survey.code, data['metaData']);
          this.plan.surveyMetaData.copyMediaTypeData(
            survey.code,
            data['medTypes']
          );
          survey.units = this.plan.surveyMetaData.meta(survey.code).reportUnits;
          survey.unitsText = this.plan.surveyMetaData.meta(
            survey.code
          ).reportUnitText;

          ob.next();
          ob.complete();
        });
      }
    });
  }

  getMultiSurveyCompatibility(surveyCodes: string[]): Observable<boolean> {
    return new Observable((res) => {
      if (surveyCodes.length === 1) {
        this.plan.surveyCompatibility.updateSingleSurvey(surveyCodes[0]);
        res.next(true);
        res.complete();
      } else {
        this.engineService
          .multiSurveyCompatibility(surveyCodes)
          .subscribe((results) => {
            this.plan.surveyCompatibility.update(results);
            res.next(!!results.success);
            res.complete();
          });
      }
    });
  }

  // if the base target does not contain any vehicles but there are targets that do, then rebuild the base target vehicles
  // basically repair the baseTagret object as some (older) campaigns and templates didn't save the base vehicle audiences correctly.
  private intialiseBaseTarget(): Observable<boolean> {
    if (
      this.plan.targets.length > 0 &&
      this.plan.targets[0].vehicles.length > 0
    ) {
      // count the mnemonics excluding duplicates from the first target to check if baseTarget vehicles need to be rebuilt
      const codes = [];
      let nonDuplicatedVehicles: TargetVehicle[] = [];
      this.plan.targets[0].vehicles.forEach((veh) => {
        !codes.includes(veh.mnemonic) ? nonDuplicatedVehicles.push(veh) : null;
        codes.push(veh.mnemonic);
      });

      const initRequired =
        this.plan.baseTarget.vehicles.length !== nonDuplicatedVehicles.length;

      return !initRequired
        ? of(true)
        : new Observable((ob) => {
            this.plan.baseTarget.vehicles = [];
            this.plan.baseTarget.addVehicles(nonDuplicatedVehicles, false);
            this.updateTargetVehicleAudiences(
              this.plan.baseTarget,
              false
            ).subscribe((res) => {
              ob.next(!!res);
              ob.complete();
            });
          });
    } else {
      // no targets or vehicles to work with yet
      return of(true);
    }
  }

  private getRequestedSurvey(
    surveyRequest: { code: string; authGroup: string },
    surveys: DocumentSurvey[]
  ): DocumentSurvey {
    if (!surveyRequest) return null;

    // check the requested survey is in the cleared list
    let survey: DocumentSurvey = surveys.find(
      (survey) => survey.code === surveyRequest.code
    );

    // check the authGroup is in the authGroups list (Also backwards compatilibity for missing authGroups)
    if (survey) {
      if (!surveyRequest.authGroup) {
        surveyRequest.authGroup = survey.authorizationGroups.length
          ? survey.authorizationGroups[0]
          : '';
      }
      survey = survey.authorizationGroups.includes(surveyRequest.authGroup)
        ? survey
        : null;

      if (survey) {
        survey.authorizationGroup = surveyRequest.authGroup;
      }
    }
    return survey;
  }

  private getMostRecentSurvey(
    clearedSurveys: DocumentSurvey[]
  ): DocumentSurvey {
    const code = this.recentSurveys.length ? this.recentSurveys[0].code : '';
    let recentSurvey = clearedSurveys.find((survey) => survey.code === code);

    // assign the saved authorization group
    if (recentSurvey) {
      recentSurvey.authorizationGroup =
        this.recentSurveys[0].authorizationGroup;

      // if an authGroup was not specificed, use the first from the list (backwards compatibility)
      if (!recentSurvey.authorizationGroup) {
        recentSurvey.authorizationGroup = recentSurvey.authorizationGroups
          .length
          ? recentSurvey.authorizationGroups[0]
          : '';
      }
    } else {
      recentSurvey = clearedSurveys.length ? clearedSurveys[0] : null;
    }
    return recentSurvey;
  }

  // get the top 10 recent surveys list from the preference service
  public getRecentSurveysList() {
    this.recentSurveys = [];

    this.userPreferenceService
      .hasItem(environment.userPreferences.lastTenSurveysUsed)
      .pipe(first())
      .subscribe(async (userPrefExists: boolean) => {
        if (userPrefExists) {
          this.userPreferenceService
            .getItem<Survey[]>(environment.userPreferences.lastTenSurveysUsed)
            .pipe(first())
            .subscribe((surveyList: Survey[]) => {
              this.recentSurveys = surveyList;
              this.loggerService.info(
                'User preference fetched successfully',
                surveyList
              );
            });
        }
      });
  }

  public getSelectedSurveysUsed() {
    this.selectedSurveysUsed = [];

    this.userPreferenceService
      .hasItem(environment.userPreferences.selectedSurveysUsed)
      .pipe(first())
      .subscribe(async (userPrefExists: boolean) => {
        if (userPrefExists) {
          this.userPreferenceService
            .getItem<Survey[]>(environment.userPreferences.selectedSurveysUsed)
            .pipe(first())
            .subscribe((surveyList: Survey[]) => {
              this.selectedSurveysUsed = surveyList;
              this.loggerService.info(
                'User preference fetched successfully',
                surveyList
              );
            });
        }
      });
  }

  private getSurveyPreferenceOption() {
    this.surveyPreferenceOption = 0;

    this.userPreferenceService
      .hasItem('manageSurveyPreference')
      .pipe(first())
      .subscribe(async (userPreferenceExist: boolean) => {
        if (userPreferenceExist) {
          this.userPreferenceService
            .getItem<number>('manageSurveyPreference')
            .pipe(first())
            .subscribe((selectedNumber: number) => {
              this.surveyPreferenceOption = selectedNumber;
            });
        }
      });
  }

  public setSurveyPreferenceOption(
    surveyPreference: number
  ): Observable<boolean> {
    // log result and return subscription
    const handleComplete = (
      observable: Subscriber<boolean>,
      success: boolean,
      msg: string,
      error?: any
    ) => {
      success
        ? this.loggerService.info(msg)
        : this.loggerService.error(msg, error);
      observable.next(success);
      observable.complete();
    };

    return new Observable((observable) => {
      if (surveyPreference !== undefined) {
        this.userPreferenceService
          .setItem<number>('manageSurveyPreference', surveyPreference)
          .pipe(first())
          .subscribe(
            () =>
              handleComplete(
                observable,
                true,
                'Manage survey user preference stored successfully'
              ),
            (error: any) => {
              handleComplete(
                observable,
                false,
                'Error storing manage survey user preference',
                error
              );
            }
          );
      }
    });
  }

  // set the last selected survey(s) list in the preference service
  public setSelectedSurveysUsed(surveys: Survey[]): Observable<boolean> {
    // log result and return subscription
    const handleComplete = (
      observable: Subscriber<boolean>,
      success: boolean,
      msg: string,
      error?: any
    ) => {
      success
        ? this.loggerService.info(msg)
        : this.loggerService.error(msg, error);
      observable.next(success);
      observable.complete();
    };

    return new Observable((observable) => {
      this.selectedSurveysUsed = surveys;
      this.userPreferenceService
        .setItem<Survey[]>(
          environment.userPreferences.selectedSurveysUsed,
          surveys
        )
        .pipe(first())
        .subscribe(
          () =>
            handleComplete(
              observable,
              true,
              'User preference stored successfully'
            ),
          (error: any) =>
            handleComplete(
              observable,
              false,
              'Error storing user preference',
              error
            )
        );
    });
  }

  // set the top 10 recent surveys list from the preference service. checking for unique surveys and max 10
  public setRecentSurveysList(surveys: Survey[]): Observable<boolean> {
    // log result and return subscription
    const handleComplete = (
      observable: Subscriber<boolean>,
      success: boolean,
      msg: string,
      error?: any
    ) => {
      success
        ? this.loggerService.info(msg)
        : this.loggerService.error(msg, error);
      observable.next(success);
      observable.complete();
    };

    return new Observable((observable) => {
      this.userPreferenceService
        .hasItem(environment.userPreferences.lastTenSurveysUsed)
        .pipe(first())
        .subscribe(
          async (userPrefExists: boolean) => {
            if (userPrefExists) {
              this.userPreferenceService
                .getItem<Survey[]>(
                  environment.userPreferences.lastTenSurveysUsed
                )
                .pipe(first())
                .subscribe(
                  (surveyList: Survey[]) => {
                    surveyList.forEach((savedSurvey) => {
                      if (
                        surveys.filter(
                          (survey) => survey.code === savedSurvey.code
                        ).length !== 0
                      ) {
                        surveyList.splice(surveyList.indexOf(savedSurvey), 1);
                      }
                    });
                    let surveysToSave = uniqBy(
                      surveys.concat(surveyList),
                      'code'
                    );
                    if (surveysToSave.length > 10) {
                      surveysToSave = surveysToSave.slice(0, 10);
                    }
                    this.recentSurveys = surveysToSave;
                    this.userPreferenceService
                      .setItem<Survey[]>(
                        environment.userPreferences.lastTenSurveysUsed,
                        surveysToSave
                      )
                      .pipe(first())
                      .subscribe(
                        () =>
                          handleComplete(
                            observable,
                            true,
                            'User preference stored successfully'
                          ),
                        (error: any) =>
                          handleComplete(
                            observable,
                            false,
                            'Error storing user preference',
                            error
                          )
                      );
                  },
                  (error: any) =>
                    handleComplete(
                      observable,
                      false,
                      'Error retreiving user preference',
                      error
                    )
                );
            } else {
              // userpref does not currently exist
              if (surveys.length > 10) {
                surveys = surveys.slice(0, 10);
              }
              this.userPreferenceService
                .setItem<Survey[]>(
                  environment.userPreferences.lastTenSurveysUsed,
                  surveys
                )
                .pipe(first())
                .subscribe(
                  () =>
                    handleComplete(
                      observable,
                      true,
                      'User preference stored successfully'
                    ),
                  (error: any) =>
                    handleComplete(
                      observable,
                      false,
                      'Error storing user preference',
                      error
                    )
                );
              this.recentSurveys = surveys;
            }
          },
          (error: any) => (error: any) =>
            handleComplete(
              observable,
              false,
              'Error setting user preference',
              error
            )
        );
    });
  }

  // fetch all cleared surveys and select the first or whatever was passed in, then calc base pop and sample
  prepareSurveys(
    surveyRequests: Array<{ code: string; authGroup: string }> = null
  ): Observable<CampaignPreparationResponse> {
    return new Observable((observable) => {
      let message = '';
      combineLatest([
        this.userPreferenceService
          .getItem<DocumentFullSurvey[]>(
            environment.userPreferences.lastTenSurveysUsed
          )
          .pipe(first()),
        this.codebookService.getSurveys().pipe(first()),
      ]).subscribe(
        ([lastTenSurveysList, allClearedSurveys]: [
          DocumentFullSurvey[],
          DocumentSurvey[]
        ]) => {
          // survey selected from multi data import dialog in dashboard. Should only be used for current run

          const surveyFromImportDialog = this.plan.multiSurveys.selectedSurvey;
          if (surveyFromImportDialog) {
            this.selectedSurveysUsed = [surveyFromImportDialog as Survey];
          }
          const filteredSurveysUsed = this.selectedSurveysUsed.filter(
            (surv) =>
              !!allClearedSurveys.find(
                (el) =>
                  el.code === surv.code &&
                  el.authorizationGroups.some(
                    (authGroup) =>
                      authGroup === surv.authorizationGroup ||
                      authGroup === surv.code
                  )
              )
          );

          const filteredSurveysReq = surveyRequests?.filter(
            (surv) =>
              !!allClearedSurveys.find(
                (el) =>
                  el.code === surv.code &&
                  el.authorizationGroups.some(
                    (authGroup) =>
                      authGroup === surv.authGroup || authGroup === surv.code
                  )
              )
          );

          if (
            surveyRequests &&
            surveyRequests.length !== filteredSurveysReq.length
          ) {
            observable.next({
              surveyCode: null,
              authorizationGroup: null,
              message:
                'Survey(s) unavailable. Please contact your sales representative.',
              success: false,
            });
            observable.complete();
          }

          // if there is currently no campaign, fetch the full survey and configure
          else if (!this.plan.campaignStarted || surveyRequests) {
            // match recent survey list to those from cleared list
            this.selectedSurveysUsed =
              filteredSurveysUsed.length === this.selectedSurveysUsed.length
                ? this.selectedSurveysUsed
                : [];

            const recentSurveys = !surveyRequests
              ? this.selectedSurveysUsed.length //if there are no saved selected surveys, use the most recent survey
                ? this.selectedSurveysUsed
                : lastTenSurveysList && lastTenSurveysList.length
                ? lastTenSurveysList
                : null
              : null;
            this.getSurveyPreferenceOption();
            const requestedSurveys = [];
            surveyRequests?.forEach((survey) => {
              requestedSurveys.push(
                this.getRequestedSurvey(survey, allClearedSurveys)
              );
            });

            const surveysToUse =
              requestedSurveys[0] ||
              recentSurveys ||
              (allClearedSurveys.length ? allClearedSurveys[0] : undefined);
            let surveyCodes = requestedSurveys.map((s) => s.code);
            const reqs = [];

            if (surveysToUse) {
              if (surveysToUse.length) {
                surveyCodes = surveyCodes.length
                  ? surveyCodes
                  : surveysToUse.map((s) => s.code);
                reqs.push(this.getMultiSurveyCompatibility(surveyCodes));
                surveysToUse.forEach((survToUse) => {
                  reqs.push(
                    this.codebookService.getSurvey(
                      survToUse.code,
                      survToUse.authorizationGroup,
                      true
                    )
                  );
                });
              } else {
                surveyCodes = surveyCodes.length
                  ? surveyCodes
                  : [surveysToUse.code];
                reqs.push(this.getMultiSurveyCompatibility(surveyCodes));
                reqs.push(
                  this.codebookService.getSurvey(
                    surveysToUse.code,
                    surveysToUse.authorizationGroup,
                    true
                  )
                );
              }
            } else {
              reqs.push(of(null));
            }
            requestedSurveys?.slice(1)?.forEach((survey) => {
              survey &&
                reqs.push(
                  this.codebookService.getSurvey(
                    survey.code,
                    survey.authorizationGroup,
                    true
                  )
                );
            });
            forkJoin(reqs).subscribe((surveys: Array<DocumentFullSurvey>) => {
              surveys.shift(); // remove first element as that's the return value from 'verifySurveyCompatibility' and not a survey
              surveys = surveys.filter((survey) => !!survey);

              let primarySurvey = surveys
                ? this.plan.selectedSurveys?.find(
                    (selectedSurveys) =>
                      surveys.find(
                        (survey) =>
                          survey.code === selectedSurveys.code &&
                          survey.authorizationGroup ===
                            selectedSurveys.authorizationGroup
                      ) && selectedSurveys.isPrimary
                  )
                : null;
              // get the full details of the survey from the DocumentSurveyFull
              if (primarySurvey) {
                const primarySurveyFromList = surveys.find(
                  (survey) =>
                    survey.code === primarySurvey.code &&
                    survey.authorizationGroup ===
                      primarySurvey.authorizationGroup
                );
                const primarySurveyIndex = surveys.indexOf(
                  primarySurveyFromList
                );
                primarySurvey.isCurrent = true;
                primarySurvey.isPrimary = true;
                if (primarySurveyIndex >= 0) {
                  surveys[primarySurveyIndex] = primarySurvey;
                }
              } else {
                if (surveys && surveys.length) {
                  primarySurvey = surveys[0];
                  primarySurvey.isCurrent = true;
                  surveys[0] = primarySurvey;
                }
              }
              this.plan.campaignStarted = !!primarySurvey;
              this.plan.selectedSurveys = surveys;
              this.plan.baseTarget
                ? this.plan.baseTarget.clear()
                : new Target();
              this.dirty(this.plan.campaignStarted);

              const reqs = [];
              if (primarySurvey) {
                reqs.push(this.getSurveyMetaData(primarySurvey));
                reqs.push(this.prepareBaseTarget(primarySurvey));
                this.plan.columns.prepare(
                  this.columnsService.getColumns(primarySurvey.code)
                );
              } else {
                reqs.push(of(false));
                message =
                  'You have not been assigned any media surveys.  Please contact your sales representative.';
              }

              forkJoin(reqs).subscribe(() => {
                // timeout needed because currentSurvey needs to be updated
                setTimeout(() => {
                  const prepResponse: CampaignPreparationResponse = {
                    surveyCode: this.plan.currentSurvey?.code,
                    authorizationGroup:
                      this.plan.currentSurvey?.authorizationGroup,
                    success: !!this.plan.currentSurvey,
                    message,
                  };
                  observable.next(prepResponse);
                  observable.complete();
                }, 250);
              });
            });
          } else {
            // campaign up and running and no survey changes, so all is successful
            observable.next({
              success: true,
              message: '',
              surveyCode: this.plan.currentSurvey.code,
              authorizationGroup: this.plan.currentSurvey.authorizationGroup,
            });
            observable.complete();
          }
        }
      );
    });
  }

  updateSurveyMetadata(survey: DocumentFullSurvey): Observable<boolean> {
    return new Observable((observable) => {
      const reqs = [];
      reqs.push(this.getSurveyMetaData(survey));
      reqs.push(this.prepareBaseTarget(survey));
      forkJoin(reqs).subscribe(() => {
        observable.next(true);
        observable.complete();
      });
    });
  }

  prepareBaseTarget(survey: DocumentFullSurvey): Observable<boolean> {
    if (!survey) return of(false);
    if (survey.population) return of(true);

    return new Observable((ob) => {
      const target = this.engineService.getTargetFromCoding(survey, '#1');
      this.plan.baseTarget.documentTarget = target;
      this.engineService
        .getMultiTargetEvaluation([target])
        .subscribe((res: MultiTargetEvaluationResponse) => {
          target.title = 'All Respondents';
          target.population = res.populations[0];
          target.sample = res.samples[0];

          survey.population = res.populations[0];
          survey.sample = res.samples[0];

          ob.next(true);
          ob.complete();
        });
    });
  }

  /**
   * Loads an Explore Report from its ID
   * @param {string} documentId - The ID of the Explore Document
   * @returns {Observable<LoadExploreReportResult>} An observable that emits a result containing the success or failure
   * status along with the loaded Explore Report file, if any
   */
  loadExploreReportFromId(
    documentId: string
  ): Observable<LoadExploreReportResult> {
    return new Observable((observable) => {
      this.documentService.get(documentId).subscribe((document) => {
        if (!document) {
          observable.next({
            success: false,
            errors: [`Failed to load the Explore report '${documentId}'`],
            exploreFile: null,
          });
          observable.complete();
        } else {
          observable.next({
            success: true,
            exploreFile: document,
          });
          observable.complete();
        }
      });
    });
  }

  // load a document from its Id
  loadDocumentFromId(
    documentId: string,
    origin: DocumentOrigin
  ): Observable<LoadDocumentResult> {
    return new Observable((observable) => {
      this.documentService.get(documentId).subscribe((document) => {
        if (!document) {
          observable.next({
            success: false,
            errors: [`Failed to load '${documentId}'`],
            campaignFile: null,
          });
          observable.complete();
        } else {
          this.loadDocument(
            document as TupDocument<DocumentCampaign>,
            origin
          ).subscribe((success) => {
            observable.next(success);
            observable.complete();
          });
        }
      });
    });
  }

  checkSurveyList() {
    return new Observable((observer) => {
      if (this.surveysList && this.surveysList.length > 0) {
        observer.next(true);
        observer.complete();
      } else {
        this.codebookService
          .getSurveys()
          .subscribe((surveys: DocumentSurvey[]) => {
            this.surveysList = surveys;
            observer.next(true);
            observer.complete();
          });
      }
    });
  }

  /**
   * Loads a DocumentCampaign into the planning object taking into account templates and autosaved docs
   *
   * @returns Observable of boolean.  The actual document now consumed into the plan object
   */

  loadDocument(
    document: TupDocument<DocumentCampaign>,
    origin: DocumentOrigin
  ): Observable<LoadDocumentResult> {
    return !document
      ? of({
          success: false,
          messages: ['No document to load'],
          campaignFile: null,
        })
      : new Observable((observable) => {
          this.checkSurveyList().subscribe(() => {
            const surveys = document.content.selectedSurveys?.length
              ? document.content.selectedSurveys
              : [document.content.survey];
            const filteredSurveys = surveys.filter((survey) =>
              this.surveysList.some(
                (el) =>
                  el.code === survey.code &&
                  el.authorizationGroups.some(
                    (group) =>
                      group === survey.authorizationGroup ||
                      group === survey.code
                  )
              )
            );
            if (filteredSurveys.length !== surveys.length) {
              observable.next({
                success: false,
                campaignFile: document,
              });
              observable.complete();
              this.documentDialogService.openCampaignWithNotAvailableSurveysError();
              return;
            }

            const requests = [];
            const currentDocumentId = this.plan.documentId;

            // multiSurveys.clearAll can't be part of this.plan.clearAll() because it causes issues when importing multisurvey from dashboard
            this.plan.multiSurveys.clearAll();
            this.clearAll();
            this.plan.document = null;
            // bits and bobs depending on being a template or not

            // key parts of the campaign may be compressed based on the version being non zero
            const isCompressed = !!document.content.header.version;
            if (isCompressed) {
              document.content.baseTarget = Compression.decompress(
                <string[]>document.content.baseTarget
              );

              document.content.targets = Compression.decompress(
                <string[]>document.content.targets
              );

              document.content.schedules = Compression.decompress(
                <string[]>document.content.schedules
              );

              document.content.multiSurveys = document.content.multiSurveys
                ? Compression.decompress(
                    <string[]>document.content.multiSurveys
                  )
                : [];

              document.content.header.version = 0;
            }

            this.plan.document = cloneDeep(document);
            this.plan.title = document.metadata.name;

            // campaign or autoSaved campaign
            this.plan.description =
              origin === DocumentOrigin.autosaved
                ? ''
                : document.metadata.description;
            this.plan.document.metadata.type =
              TupDocumentTypes[TupDocumentTypeId.CMP_CAMPAIGN];

            const docId = [
              DocumentOrigin.autosaved,
              DocumentOrigin.template,
            ].includes(origin)
              ? currentDocumentId
              : document.metadata.id;
            this.plan.documentId = docId;
            this.plan.document.metadata.id = docId;

            if (
              [DocumentOrigin.autosaved, DocumentOrigin.template].includes(
                origin
              )
            ) {
              this.plan.title = this.NEW_CAMPAIGN_TITLE; // this is while we are force loading autosaved docs from the editor:ngview
              this.plan.description = '';
            }

            if (document.content.chartSettings) {
              this.plan.chartSettings = document.content.chartSettings;
            }

            if (document.content.vehicleGroups) {
              this.plan.vehicleGroups.loadCampaign(
                document.content.vehicleGroups
              );
            }

            if (document.content.freqDistributionSettings) {
              this.plan.freqDistributionSettings =
                document.content.freqDistributionSettings;
            } else {
              this.plan.freqDistributionSettings =
                defaultFreqDistributionSettings;
            }
            if (document.content.effectiveReach) {
              this.plan.effectiveReach = {
                ...document.content.effectiveReach,
                active: true, // manually set for compatibility with old campaigns
              };
            }
            if (document.content.columns) {
              const visibleColumns =
                document.content.columns.visibleColumns ||
                this.plan.columns.defaultColumns.map((col) => col.columnDef);
              this.plan.columns.loadDocument(
                visibleColumns,
                this.plan.effectiveReach
              );
            }
            if (document.content.mediaSettings) {
              const mediaSettings = document.content.mediaSettings;
              if (mediaSettings.metricsOrder) {
                this.plan.mediaSettings.metricsOrder =
                  mediaSettings.metricsOrder;
              }
              if (mediaSettings.orderBy) {
                this.plan.mediaSettings.orderBy = mediaSettings.orderBy;
              }
              if (mediaSettings.selectedMetrics) {
                this.plan.mediaSettings.selectedMetrics =
                  mediaSettings.selectedMetrics;
              }
              if (mediaSettings.sortBy) {
                this.plan.mediaSettings.sortBy = mediaSettings.sortBy;
              }
              if (mediaSettings.sortBy) {
                this.plan.mediaSettings.selectedColumn =
                  mediaSettings.selectedColumn;
              }
            }
            if (
              document.content.multiSurveys &&
              document.content.multiSurveys.length
            ) {
              this.plan.multiSurveys.loadCampaign(
                <MultiSurvey[]>document.content.multiSurveys
              );
            }

            if (document.content.costings) {
              this.plan.costings.loadCampaign(document.content.costings);
            }

            // survey we're using (TODO: check clearances)
            // getSurveys() call made to enable clearances as well as fetching the column definitions
            if (document.content.survey) {
              this.plan.selectedSurveys = [
                {
                  ...document.content.survey,
                  isCurrent: true,
                  isPrimary: true,
                },
              ];
            } else {
              const primarySurvey = document.content.selectedSurveys.find(
                (survey) => survey.isPrimary
              );
              const currentSurvey =
                document.content.selectedSurveys.find(
                  (survey) => survey.isCurrent
                ) || document.content.selectedSurveys[0];
              if (primarySurvey) {
                this.plan.selectedSurveys = [primarySurvey];
              } else {
                this.plan.selectedSurveys = [currentSurvey];
              }
              this.plan.selectedSurveys[0].isCurrent = true;
              document.content.survey = this.plan.selectedSurveys[0];
            }
            if (
              document.content.selectedSurveys &&
              document.content.selectedSurveys.length
            ) {
              document.content.selectedSurveys
                .filter(
                  (selectedSurvey) =>
                    selectedSurvey.code !== document.content.survey.code
                )
                .forEach((selectedSurvey) =>
                  this.plan.selectedSurveys.push(selectedSurvey)
                );
            }
            requests.push(this.codebookService.getSurveys());
            requests.push(
              this.getMultiSurveyCompatibility(
                this.plan.selectedSurveys.map((s) => s.code)
              )
            );
            requests.push(
              ...this.plan.selectedSurveys.map((selectedSurvey) =>
                this.getSurveyMetaData(selectedSurvey)
              )
            );

            // get a copy of the brief  (V2 really, but the data is here so can save it but ignore it)
            this.plan.brief = cloneDeep(document.content.brief);

            // base target
            const docBaseTarget = <DocumentTargetResults>(
              document.content.baseTarget
            );
            this.plan.baseTarget.clear();
            this.plan.baseTarget.id = docBaseTarget.id;
            this.plan.baseTarget.status =
              docBaseTarget.status || TargetStatus.active;
            this.plan.baseTarget.documentTarget = docBaseTarget.documentTarget;
            this.plan.baseTarget.addVehicles(docBaseTarget.vehicles, true);

            // build planning targets
            (<DocumentTargetResults[]>document.content.targets).forEach(
              (docTarget) => {
                // older campaign files would not have any planning/addr settings so needs correcting
                if (
                  !docTarget.documentTarget.planningTarget &&
                  !docTarget.documentTarget.addressableTarget
                ) {
                  docTarget.documentTarget.planningTarget = true;
                }
                docTarget.vehicles.forEach((vehicle) => {
                  if (!vehicle.survey) {
                    vehicle.survey = {
                      code: document.content.survey.code,
                      title: document.content.survey.title,
                      authGroup: document.content.survey.authorizationGroup,
                    };
                  }
                });

                this.plan.addTarget(docTarget.documentTarget);
                const target = this.plan.targets[this.plan.targets.length - 1];

                target.id = docTarget.id;
                target.status = docTarget.status;
                target.vehicles = cloneDeep(docTarget.vehicles);
                target.initialiseVehicles();
              }
            );

            requests.push(this.intialiseBaseTarget());

            // build schedules.  If this is a template object then schedules contain 1 empty "plan" schedule
            let preSpotplanCampaign = false;
            (<DocumentSchedule[]>document.content.schedules).forEach(
              (docSchedule) => {
                this.plan.addSchedule(docSchedule.name);
                const sched =
                  this.plan.schedules[this.plan.schedules.length - 1];

                // vehicles within schedules
                docSchedule.vehicles.forEach((docVehicle) => {
                  const target = this.plan.targets.find(
                    (target) => target.id === docVehicle.targetId
                  );

                  let added = false;

                  const targetVehicle = this.plan.targets[0].vehicle(
                    docVehicle.vehicleId
                  );
                  const isBroadcast = !!(
                    targetVehicle && targetVehicle.dayparts.length
                  );

                  // if broadcast, record the totals and create a spotplan for the dayparts
                  if (
                    docVehicle.result.type === ResultType.broadcast ||
                    isBroadcast
                  ) {
                    docVehicle.result.type = ResultType.broadcast; // correct possible inconsistency from previous campaign files
                    sched.addResults(
                      target,
                      docVehicle.vehicleId,
                      docVehicle.result
                    );

                    sched.spotplans.addSpotplan(targetVehicle);
                    added = true;
                  }

                  // if dayparts, lookup schedule and add results to the spotplan object instead
                  if (docVehicle.result.type === ResultType.daypart) {
                    preSpotplanCampaign = true;
                    const spotplan = sched.spotplans.findSpotplan(
                      docVehicle.result.reference
                    );
                    spotplan.addResults(
                      docVehicle.vehicleId,
                      docVehicle.targetId,
                      0,
                      {
                        inserts: docVehicle.result.inserts,
                        GRPs: target.population
                          ? (docVehicle.result.impressions /
                              target.population) *
                            100
                          : 0,
                        impressions: docVehicle.result.impressions,
                        reach000: docVehicle.result.reach * target.population,
                        reachPct: docVehicle.result.reach * 100,
                        costMethod: docVehicle.result.costMethod,
                        cpm: docVehicle.result.cpm,
                        cpp: docVehicle.result.cpp,
                        unitCost: docVehicle.result.unitCost,
                        totalCost: docVehicle.result.totalCost,
                      }
                    );
                    added = true;
                  }

                  if (!added) {
                    sched.addResults(
                      target,
                      docVehicle.vehicleId,
                      docVehicle.result
                    );
                  }
                });

                // load any spotplans for this schedule
                if (docSchedule.spotplans) {
                  sched.spotplans.loadCampaign(docSchedule.spotplans);
                }

                // older campaign was loaded (results array still contained daypart results)
                if (preSpotplanCampaign) {
                  // each target
                  this.plan.targets.forEach((target) => {
                    // each vehicle
                    target.vehicles.forEach((vehicle) => {
                      // if broadcast, get the total values and copy to week 1
                      if (vehicle.dayparts.length) {
                        const broadcastVeh = sched.vehicle(target, vehicle.id);
                        const broadcastRes = broadcastVeh.result;
                        const spotplan = sched.spotplans.findSpotplan(
                          vehicle.id
                        );
                        const inserts = spotplan.calculateInserts(
                          0,
                          -1,
                          target.id
                        );

                        sched.addResults(target, broadcastVeh.vehicleId, {
                          inserts,
                        });

                        if (spotplan) {
                          const wk1Results: DaypartMetrics = {
                            inserts,
                            reachPct: broadcastRes.reachPct,
                            reach000: broadcastRes.reach000,
                            impressions: broadcastRes.impressions,
                            GRPs: broadcastRes.GRPs,
                            unitCost: broadcastRes.unitCost,
                            totalCost: broadcastRes.totalCost,
                            cpp: broadcastRes.cpp,
                            cpm: broadcastRes.cpm,
                            costMethod: broadcastRes.costMethod,
                          };
                          spotplan.setWeekTotal(0, target.id, wk1Results);
                        }
                      }
                    });
                  });
                }

                // schedule totals
                docSchedule.scheduleTotal.forEach((docSchedTotal) => {
                  const target = this.plan.targets.find(
                    (target) => target.id === docSchedTotal.targetId
                  );

                  // correction for older document files with the tag missing
                  if (typeof docSchedTotal.tag === 'undefined') {
                    docSchedTotal.tag =
                      docSchedTotal.group === 'total'
                        ? ScheduleTotalTag.total
                        : ScheduleTotalTag.mediatype;
                  }

                  const res = sched.getScheduleTotal(
                    docSchedTotal.group,
                    docSchedTotal.tag,
                    target
                  );
                  res ? res.result.addResults(docSchedTotal.result) : null;
                });
              }
            );

            // restore to the correct step if it was recorded, else fall back to best guess
            if (origin === DocumentOrigin.upload) {
              this.plan.campaignStage = '';
              this.plan.documentId = UNSAVED_DOCUMENT;
            } else {
              this.plan.campaignStage =
                document.content.header.campaignStage ||
                this.plan.getCampaignStage();
            }

            this.plan.campaignStarted = true;
            this.dirty(
              [
                DocumentOrigin.autosaved,
                DocumentOrigin.template,
                DocumentOrigin.upload,
              ].includes(origin)
            );

            // wait for pending requests
            forkJoin(requests).subscribe((data) => {
              // TODO: Survey clearance
              const surveyCleared = this.codebookService.clearedSurveys.find(
                (survey) => survey.code === this.plan.currentSurvey.code
              );

              this.plan.columns.prepare(
                this.columnsService.getColumns(this.plan.currentSurvey.code)
              );

              // do a full R&F evaluation if the document source was from an upload
              const fullEvaluation =
                origin === DocumentOrigin.upload
                  ? this.engineService.evaluateMediaPlanAllSchedules(
                      this.plan.targets,
                      this.plan.schedules,
                      this.plan.vehicleGroups.groups,
                      true
                    )
                  : of([true]);

              fullEvaluation.subscribe((success: boolean[]) => {
                observable.next({
                  success: !success.includes(false),
                  campaignFile: document,
                });
                observable.complete();
              });
            });
          });
        });
  }

  // brieifng items refreshed for a new campaign
  clearBriefing(): DocumentBrief {
    return this.plan.clearBriefing(this.authService.user.attributes?.name);
  }

  /**
   * Either create or update an existing autosave campaign.
   * Will ensure the container is the users' local drive
   * using the correct document type
   *
   * @param createNewCampaign flag to indicate if new campaign dialog is required
   * @param forcePrompt Force save dialog to appear whether new campaign or not (edit name, desc of current campaign)
   * @returns Observable<bool>
   */
  updateAutoSaveCampaign(): Observable<boolean> {
    console.log(
      `updateAutoSaveCampaign: started (autosaveId= ${this.autoSaveService.document?.metadata.id} container=${this.documentService.myDriveContainer.name})`
    );

    //  autoSaved doc not found so create a new document of the correct type
    if (!this.autoSaveService.document?.metadata.id) {
      return this.createCampaign(
        TupDocumentTypeId.CMP_CURRENT_RUN,
        this.documentService.myDriveContainer
      );
    } else {
      // else update our existing doc with modified and new contents
      const doc = this.autoSaveService.document;
      doc.metadata.modified = new Date().getTime();
      doc.metadata.container = this.documentService.myDriveContainer;
      doc.metadata.name = this.plan.title;
      doc.metadata.description = this.plan.description || '';
      doc.content = this.plan.asDocumentCampaign();

      return this.documentService.updateDocument(doc, true);
    }
  }

  /**
   * Creating or updating a campaign. If needed, the save dialog will be used.
   *
   * @param createNewCampaign flag to indicate if new campaign dialog is required
   * @param forcePrompt Force save dialog to appear whether new campaign or not (edit name, desc of current campaign)
   * @param showDrives Display drive selection form (save the campaign on shared or personal drive)
   * @returns Observable<bool>
   */
  saveCampaignWithPrompts(
    createNewCampaign: boolean,
    forcePrompt: boolean,
    showDrives: boolean,
    saveAsDialogOptions?: SaveAsDialogOptionsModel
  ): Observable<boolean> {
    const handleComplete = (observer: Subscriber<boolean>, value: boolean) => {
      observer.next(value);
      observer.complete();
    };

    return new Observable((ob) => {
      let createNew =
        createNewCampaign || this.plan.documentId === UNSAVED_DOCUMENT;
      if (!this.plan.title || createNew || forcePrompt) {
        // prepare and show save dialog
        const dialogData: SaveDocumentDialogData = getSaveDocumentDialogData(
          'Save',
          'Campaign',
          {
            dialogTitle: 'Name your campaign',
            autoFocus: {
              formControl: 'Name',
              select: createNewCampaign,
              delay: 251,
            },
            enableSaveOnInit: true,
            nameFormControlValue: this.plan.title,
            descriptionFormControlValue: this.plan.description,
            showDrives,
            document: this.plan.document,
          },
          'Add a description (optional)'
        );

        if (saveAsDialogOptions) {
          dialogData.nameFormControlValue = saveAsDialogOptions.campaignName;
          dialogData.descriptionFormControlValue =
            saveAsDialogOptions.campaignDescription;
        }

        const dialogConfig: MatDialogConfig = { data: dialogData };
        this.documentDialogService
          .openSaveDocumentDialog(dialogData)
          .subscribe((dialogResult: SaveDocumentDialogResult) => {
            // cancel clicked.. return false
            if (!dialogResult) {
              handleComplete(ob, false);
            } else {
              // ok clicked..  Either create new campaign or update existing
              this.plan.title = dialogResult.name;
              this.plan.description = dialogResult.description;
              this.plan.tags = dialogResult.tags;

              createNew
                ? this.createCampaign(
                    TupDocumentTypeId.CMP_CAMPAIGN,
                    dialogResult.container
                  ).subscribe((res) => {
                    handleComplete(ob, res);
                  })
                : this.updateCampaign(false).subscribe((res) =>
                    handleComplete(ob, res)
                  );
              this.dirty(false);
              this.updateAutoSaveCampaign().subscribe();
            }
          });
      } else {
        // no prompts needed so create or update campaign using current container
        createNew
          ? this.createCampaign(TupDocumentTypeId.CMP_CAMPAIGN).subscribe(
              (res) => handleComplete(ob, res)
            )
          : this.updateCampaign().subscribe((res) => handleComplete(ob, res));
        this.dirty(false);
        this.updateAutoSaveCampaign().subscribe();
      }
    });
  }

  saveCampaignAsTemplate() {
    const dialogData: SaveDocumentDialogData = getSaveDocumentDialogData(
      'Save',
      'Template',
      {
        enableSaveOnInit: true,
        autoFocus: { formControl: 'Name', select: true },
        dialogTitle: 'Save Template',
        nameFormControlValue: this.plan.title,
        descriptionFormControlValue: this.plan.description,
        showDrives: true,
        document: this.plan.document,
      }
    );

    this.documentDialogService
      .openSaveDocumentDialog(dialogData)
      .pipe(isNotNullOrUndefined())
      .subscribe((dialogResult: SaveDocumentDialogResult) => {
        this.createTemplate(
          dialogResult.name,
          dialogResult.description,
          dialogResult.container,
          dialogResult.tags
        ).subscribe();
      });
  }

  updateCampaign(
    silent?: boolean,
    document?: TupDocument<DocumentCampaign>
  ): Observable<boolean> {
    const handleComplete = (observer: Subscriber<boolean>, value: boolean) => {
      observer.next(value);
      observer.complete();
    };

    return new Observable((ob) => {
      const doc = document || this.plan.document;

      if (doc) {
        doc.metadata.modified = new Date().getTime();
        doc.metadata.name = this.plan.title;
        doc.metadata.description = this.plan.description;
        if (!document) {
          doc.content = this.plan.asDocumentCampaign();
        }

        this.documentService
          .updateDocument(doc, silent)
          .subscribe((res) => handleComplete(ob, res));
      } else {
        this.createCampaign(TupDocumentTypeId.CMP_CAMPAIGN).subscribe((res) =>
          handleComplete(ob, res)
        );
      }
    });
  }

  // create a template type campaign
  createTemplate(
    title: string,
    description: string,
    container: UserContainer,
    tags: TupTag[]
  ): Observable<boolean> {
    return new Observable((ob) => {
      const document =
        this.documentService.createDocumentObject<DocumentCampaign>(
          DocumentCampaign,
          title,
          TupDocumentTypeId.CMP_CAMPAIGN_TEMPLATE,
          []
        );
      document.metadata.description = description;
      document.metadata.tags = tags;
      document.content = this.plan.asDocumentCampaign(false);

      this.documentService
        .createDocument(document, container)
        .subscribe((succ) => {
          ob.next(succ);
          ob.complete();
        });
    });
  }

  // get the Tup Document Campaign
  getDocumentCampaign(
    campaignType: TupDocumentTypeId
  ): Observable<TupDocument<DocumentCampaign>> {
    return new Observable((ob) => {
      const document =
        this.documentService.createDocumentObject<DocumentCampaign>(
          DocumentCampaign,
          this.plan.title,
          campaignType,
          []
        );

      document.metadata.description = this.plan.description || '';
      document.metadata.tags = this.plan.tags || [];
      document.content = this.plan.asDocumentCampaign();

      ob.next(document);
      ob.complete();
    });
  }

  // create document content and call service to create document
  createCampaign(
    campaignType: TupDocumentTypeId,
    container?: UserContainer
  ): Observable<boolean> {
    return new Observable((ob) => {
      const document =
        this.documentService.createDocumentObject<DocumentCampaign>(
          DocumentCampaign,
          this.plan.title,
          campaignType,
          []
        );

      document.metadata.description = this.plan.description || '';
      document.metadata.tags = this.plan.tags || [];
      document.content = this.plan.asDocumentCampaign();

      this.documentService
        .createDocument(document, container)
        .subscribe((succ) => {
          if (succ) {
            if (campaignType == TupDocumentTypeId.CMP_CURRENT_RUN) {
              this.autoSaveService.document = document;
            } else {
              this.plan.documentId = document.metadata.id;
              this.plan.document = document;
            }
          }
          ob.next(succ);
          ob.complete();
        });
    });
  }

  containsAddressableMedia(target: Target) {
    let containsDirectMail = false;
    let containsAddressable = false;
    const addressableVehicles = target.vehicles.filter(
      (veh) => !!veh.addressableConfig
    );

    if (addressableVehicles) {
      containsDirectMail = !!addressableVehicles.find(
        (veh) => veh.addressableConfig.isDirectMail
      );
      containsAddressable = !!addressableVehicles.find(
        (veh) => !veh.addressableConfig.isDirectMail
      );
    }
    return [containsDirectMail, containsAddressable];
  }
}
