import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  DefaultVehicleCostType,
  DefaultVehicleCostsRequest,
  DefaultVehicleCostsResponse,
  GetVehicleCostsResponse,
} from '../models/costing.models';
import { MediaPlannerService } from './media-planner.service';

@Injectable({
  providedIn: 'root',
})
export class CostingService {
  constructor(
    private apiService: ApiService,
    private mediaplannerService: MediaPlannerService
  ) {}

  /**
   * Makes a request to the API to get costs for the given vehicles.
   * @param surveyProvider Survey provider to get costs from.
   * @param vehicles List of vehicles to get costs for.
   * @returns An Observable with the response from the API.
   */
  getVehicleCosts(
    surveyProvider: string,
    vehicles: string[]
  ): Observable<GetVehicleCostsResponse> {
    const costings = this.mediaplannerService.plan.costings;

    // validate the cost object before applying to defaultCost array
    const validCost = (cost) => {
      return (
        cost.costType !== DefaultVehicleCostType.notFound && cost.value !== null
      );
    };

    return new Observable((observable) => {
      const options: DefaultVehicleCostsRequest = {
        surveyProvider,
        vehicles,
      };

      this.apiService
        .request(
          'POST',
          environment.api.codebook.url,
          environment.api.codebook.endPoint.getVehicleCosts,
          { body: options }
        )
        .subscribe((results: DefaultVehicleCostsResponse) => {
          if (results.success) {
            costings.defaultCosts[surveyProvider] =
              costings.defaultCosts[surveyProvider] || {};

            const defaultCosts = costings.defaultCosts[surveyProvider];

            results.vehicleCosts.forEach((cost, vehicleIndex) => {
              if (validCost(cost)) {
                const baseCpm =
                  cost.costType === DefaultVehicleCostType.cpm
                    ? parseFloat('' + cost.value)
                    : undefined;
                const unitCost =
                  cost.costType === DefaultVehicleCostType.unitCost
                    ? parseFloat('' + cost.value)
                    : undefined;

                defaultCosts[vehicles[vehicleIndex]] = {
                  unitCost,
                  baseCpm,
                  applied: false,
                };
              }
            });
          }

          observable.next({
            success: results.success,
            message: results.message,
            vehicleCount: results.vehicleCosts.filter((cost) => validCost(cost))
              .length,
          });
          observable.complete();
        });
    });
  }
}
