import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UploadFile } from '../../models/file-upload-model';

@Component({
  selector: 'file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent implements OnInit {
  @Input() disabled: boolean = false;
  @Input() showIcon: boolean = true;
  @Input() text: string;
  @Input() acceptedFileType: string = '.json,.plan';
  @Output() uploaded: EventEmitter<UploadFile> = new EventEmitter<UploadFile>();
  @Output() uploadStarted: EventEmitter<UploadFile> =
    new EventEmitter<UploadFile>();
  @Output() uploadProgress: EventEmitter<number> = new EventEmitter<number>();
  @ViewChild('fileUpload') fileUpload: ElementRef;

  fileName = '';

  constructor() {}

  ngOnInit(): void {}

  onFileSelected(event) {
    const file: File = event.target.files[0];
    this.fileUpload.nativeElement.value = ''; // clear input

    if (file) {
      // prevent the user from uploading files that are not accepted
      // TODO: Possibly some UI required to tell the user that the file type was rejected

      // const fileExtension = `.${file.name.split('.').pop().toLocaleLowerCase()}`;
      // if (!this.acceptedFileType.toLocaleLowerCase().split(',').includes(fileExtension)) {
      //   return
      // };

      const reader = new FileReader();
      reader.readAsText(file);

      reader.onloadstart = () => {
        this.uploadStarted.emit({
          fileName: file.name,
          fileSize: this.fileSizeConvert(file.size),
        });
      };

      reader.onprogress = (data) => {
        const progress = Math.round((data.loaded / data.total) * 100);
        this.uploadProgress.emit(progress);
      };

      reader.onload = (data) => {
        this.uploaded.emit({
          fileContent: reader.result as string,
          fileName: file.name,
        });
      };
    }
  }

  private fileSizeConvert(bytes: number, decimals: number = 2) {
    if (!Number(bytes)) {
      return '0 Bytes';
    }
    const kbToBytes = 1000; // In decimal, 1 Kilobyte is equal to 1000 bytes.
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

    const index = Math.floor(Math.log(bytes) / Math.log(kbToBytes)); // get sizes array index

    // if bytes value can be converted to MB => (bytes/1000^2).toFixed(2) MB; 26141343 bytes => 26.14 MB
    return `${parseFloat((bytes / Math.pow(kbToBytes, index)).toFixed(dm))} ${
      sizes[index]
    }`;
  }
}
