import { DefaultCost, MISSING_DEFAULT_COSTS } from '../models/costing.models';
import { DocumentCostings } from '../models/document.model';
import { CostMethod } from './result';
import { Schedule } from './schedule';
import { Target } from './target';
import { TargetVehicle } from './vehicle';

export class Costings {
  public defaultCosts: {
    [surveyProvider: string]: { [mnemonic: string]: DefaultCost };
  } = {};

  /**
   * Assigns the costs for the given targets and schedules using the defaultCosts
   * that were previously retrieved with the getVehicleCosts method.
   * @param targets List of targets to assign costs for.
   * @param schedules List of schedules to assign costs for.
   * @param [override=false] If true, the default costs will be applied even if the unitCost is not 0.
   */
  public applyDefaultCosts(
    targets: Target[],
    baseTarget: Target,
    schedules: Schedule[],
    override: boolean = false
  ) {
    const appliedCosts: DefaultCost[] = [];
    targets.forEach((target) => {
      // any costs from this provider?
      const costsFromProvider = this.defaultCosts[target.survey.provider];
      if (!costsFromProvider) return;

      const multiplyfactor = 1000 / (target.survey.units || 1);

      target.vehicles.forEach((targetVehicle) => {
        // any costs for this vehicle?
        const vehicleCost = costsFromProvider[targetVehicle.mnemonic];
        if (vehicleCost) {
          // get this vehicle accross all schedules
          const scheduleVehicles = schedules.map((schedule) =>
            schedule.vehicle(target, targetVehicle.id)
          );

          // if any unitCosts accross the schedules are 0 or we're overriding, apply the default costs again
          if (
            override ||
            scheduleVehicles.find(
              (schVehicle) => schVehicle.result.unitCost === 0
            )
          ) {
            scheduleVehicles.forEach((schVehicle) => {
              if (vehicleCost.applied && !override) return;

              let unitCost = vehicleCost.unitCost;
              let baseCpm = vehicleCost.baseCpm;

              let cpm: number = undefined;
              let cpp: number = undefined;

              const baseVehicle = baseTarget.vehicleByMnemonic(
                targetVehicle.mnemonic
              );

              const costMethod: CostMethod =
                typeof unitCost === 'number'
                  ? CostMethod.unitCost
                  : CostMethod.baseCpm;

              switch (costMethod) {
                // we have a unitCost, calculate the cpm and cpp
                case CostMethod.unitCost:
                  if (baseVehicle && baseVehicle.audience) {
                    baseCpm =
                      (unitCost / baseVehicle.audience) * multiplyfactor;
                  }

                  cpm = targetVehicle.audience
                    ? (unitCost / targetVehicle.audience) * multiplyfactor
                    : 0;
                  cpp = schVehicle.result.grps
                    ? unitCost / schVehicle.result.grps
                    : undefined;
                  break;

                // we have a baseCmp, calculate the unitCost and cpp
                case CostMethod.baseCpm:
                  if (baseVehicle) {
                    const baseCost = baseCpm * baseVehicle.audience;
                    cpp = schVehicle.result.grps
                      ? baseCost / schVehicle.result.grps
                      : undefined;
                    cpm = targetVehicle.audience
                      ? baseCost / targetVehicle.audience
                      : 0;
                    unitCost =
                      (baseCpm * baseVehicle.audience) / multiplyfactor;
                  }

                  break;
              }

              schVehicle.result.addResults({
                unitCost,
                cpp,
                cpm,
                baseCpm,
                costMethod,
                costTargetId: vehicleCost.unitCost ? undefined : target.id,
              });

              appliedCosts.push(vehicleCost);
            });
          }
        }
      }); // vehicles
    }); // targets

    appliedCosts.forEach((cost) => (cost.applied = true));
  }

  public applyDefaultCostTooltips(
    vehicle: TargetVehicle,
    target: Target,
    tooltips: any,
    columns: string[]
  ) {
    const costsFromProvider = this.defaultCosts[target.survey.provider];
    if (!costsFromProvider) return;

    let hasCosts = !!costsFromProvider[vehicle.mnemonic];
    if (hasCosts) {
      hasCosts = !!(
        costsFromProvider[vehicle.mnemonic].baseCpm ||
        costsFromProvider[vehicle.mnemonic].unitCost
      );
    }

    if (!hasCosts) {
      columns.forEach((column) => {
        tooltips[column] = MISSING_DEFAULT_COSTS;
      });
    }
  }

  clear(provider: string = '') {
    if (provider) {
      delete this.defaultCosts[provider];
    } else {
      this.defaultCosts = {};
    }
  }

  /**
   * Converts the current Costings instance for saving to document storage
   * @returns a DocumentCostings object containing the default costs.
   */
  asDocument(): DocumentCostings {
    return {
      defaultCosts: this.defaultCosts,
    };
  }

  /**
   * Loads the default costs for the given campaign.
   * @param costings a DocumentCostings object with the default costs from the campaign.
   */
  loadCampaign(costings: DocumentCostings) {
    if (costings && costings.defaultCosts) {
      this.defaultCosts = costings.defaultCosts;

      // loop through all survey providers and mnemonics and set the applied flag to true if it's missing (old campaigns)
      Object.keys(this.defaultCosts).forEach((surveyProvider) => {
        Object.keys(this.defaultCosts[surveyProvider]).forEach((mnemonic) => {
          if (
            typeof this.defaultCosts[surveyProvider][mnemonic].applied ===
            'undefined'
          ) {
            this.defaultCosts[surveyProvider][mnemonic].applied = true;
          }
        });
      });
    }
  }
}
